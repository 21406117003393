import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 1080.000000 1069.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1069.000000) scale(0.100000,-0.100000)">


<path d="M7796 9862 c-3 -5 -27 -13 -53 -17 -27 -3 -79 -16 -118 -27 -38 -11
-99 -24 -135 -28 -36 -4 -103 -15 -150 -24 -47 -9 -168 -24 -270 -33 -163 -14
-473 -17 -2570 -22 -1312 -3 -2390 -9 -2397 -14 -6 -4 -47 -11 -90 -16 -43 -5
-103 -17 -133 -26 -30 -9 -73 -20 -95 -25 -49 -10 -148 -47 -236 -89 -36 -17
-69 -31 -73 -31 -3 0 -28 -15 -54 -32 -63 -43 -84 -57 -99 -64 -7 -3 -30 -22
-52 -43 -47 -44 -71 -64 -85 -71 -21 -10 -163 -166 -189 -207 -14 -23 -31 -44
-36 -48 -13 -7 -91 -170 -91 -188 0 -7 -5 -18 -12 -25 -6 -6 -15 -30 -19 -54
-4 -24 -13 -63 -19 -88 -7 -25 -12 -111 -12 -195 -1 -148 12 -232 46 -325 8
-19 21 -59 31 -88 38 -116 145 -278 282 -426 69 -76 73 -78 107 -72 37 7 44
21 27 54 -5 9 -17 34 -27 55 -10 20 -22 37 -26 37 -5 0 -8 8 -8 18 0 10 -4 22
-9 27 -9 11 -72 187 -89 250 -37 135 -47 368 -22 492 15 72 40 155 51 168 5 5
9 19 9 31 0 13 5 26 10 29 6 3 19 27 31 52 40 88 162 217 283 300 51 35 222
125 291 153 58 24 197 64 245 71 30 4 87 12 125 18 108 16 1986 15 2008 -1 16
-12 17 -184 15 -2798 -3 -2601 -4 -2788 -20 -2833 -41 -114 -132 -225 -258
-315 -67 -48 -161 -77 -290 -92 -132 -15 -152 -22 -148 -53 l3 -22 1401 -3
c1152 -2 1403 0 1407 11 8 21 -4 69 -17 64 -23 -9 -242 13 -271 27 -16 8 -57
26 -90 41 -33 16 -78 43 -101 61 -23 19 -45 34 -50 34 -5 0 -31 30 -59 67 -80
108 -89 127 -113 228 -15 64 -17 310 -19 2822 -1 1701 1 2755 7 2758 19 12
1374 8 1403 -4 16 -6 39 -11 52 -11 64 0 329 -97 348 -127 4 -7 13 -13 20 -13
19 0 204 -195 224 -237 11 -21 31 -58 45 -83 15 -25 36 -74 48 -110 11 -36 25
-78 31 -95 5 -16 15 -84 21 -150 6 -66 12 -133 13 -150 2 -16 4 -38 4 -48 2
-31 43 -17 52 17 4 15 9 361 11 767 l3 738 -21 22 c-12 12 -24 17 -28 11z"/>
<path d="M4445 2930 c-9 -30 -10 -283 -1 -298 6 -10 125 -12 209 -3 12 2 8 31
-5 32 -7 1 -40 0 -74 -1 -57 -2 -61 0 -74 27 -7 15 -12 35 -11 43 4 23 33 51
50 49 45 -4 121 2 121 10 0 5 -30 12 -67 16 -80 8 -103 19 -103 46 0 49 16 59
95 59 58 0 75 3 75 14 0 19 -18 23 -122 25 -81 2 -87 1 -93 -19z"/>
<path d="M5353 2943 c-20 -4 -22 -10 -25 -136 -4 -169 -3 -177 31 -177 25 0
26 2 23 43 -5 61 23 89 82 81 38 -6 47 -3 79 26 31 27 37 39 37 71 0 21 -4 39
-9 39 -5 0 -12 8 -15 18 -7 20 -86 46 -100 32 -4 -4 -22 -4 -39 1 -18 5 -34 8
-37 8 -3 -1 -15 -4 -27 -6z m159 -63 c11 -14 14 -28 9 -48 -6 -23 -15 -28 -55
-36 -45 -9 -50 -8 -67 13 -24 29 -24 59 -2 84 14 15 26 18 58 13 22 -4 47 -15
57 -26z"/>
<path d="M5765 2943 c-22 -3 -44 -9 -50 -14 -12 -12 -13 -298 0 -302 31 -11
45 6 45 52 0 87 48 97 98 22 17 -28 32 -54 32 -59 0 -12 30 -20 48 -14 9 3 6
15 -13 47 -32 56 -32 70 5 112 35 39 39 86 12 119 -16 18 -107 48 -130 42 -4
-1 -25 -3 -47 -5z m113 -49 c15 -11 22 -25 22 -50 0 -40 -8 -45 -76 -47 l-48
-2 -9 43 c-6 30 -5 48 4 58 15 19 79 18 107 -2z"/>
<path d="M6131 2940 c-23 -5 -24 -9 -26 -95 -1 -50 1 -119 4 -155 l6 -65 92
-3 c84 -3 92 -2 103 17 12 23 18 21 -108 25 -37 1 -52 18 -52 58 0 35 25 48
98 51 43 1 57 6 57 17 0 12 -14 15 -65 16 -78 0 -90 6 -90 43 0 36 17 51 60
51 19 0 53 5 74 11 35 10 37 12 22 21 -21 12 -138 17 -175 8z"/>
<path d="M6515 2938 c-43 -16 -55 -32 -55 -75 0 -40 32 -89 50 -78 5 3 17 1
27 -4 10 -5 33 -15 51 -22 55 -20 65 -35 46 -71 -15 -29 -18 -30 -64 -25 -27
3 -59 13 -72 21 -13 9 -29 12 -36 7 -10 -6 -6 -15 15 -39 25 -30 32 -32 92
-32 57 0 68 3 93 27 23 22 28 35 28 73 0 50 -5 55 -114 97 -65 25 -78 45 -48
75 21 21 25 22 79 10 88 -18 104 18 17 39 -49 11 -71 10 -109 -3z"/>
<path d="M6885 2935 c-22 -8 -41 -15 -42 -15 -1 0 -9 -13 -17 -28 -13 -26 -13
-32 4 -59 19 -31 91 -73 127 -73 11 0 28 -9 38 -20 44 -49 -46 -101 -114 -66
-39 21 -51 20 -51 -2 0 -31 56 -55 115 -49 81 8 115 39 115 106 0 28 -44 71
-74 71 -8 0 -18 5 -21 10 -3 6 -17 10 -30 10 -13 0 -30 3 -39 6 -19 7 -21 55
-4 72 15 15 84 16 93 2 3 -5 17 -10 31 -10 17 0 24 6 24 18 0 14 -13 21 -51
30 -61 14 -55 15 -104 -3z"/>
<path d="M7500 2936 c-51 -16 -66 -27 -94 -69 -37 -54 -35 -111 4 -165 47 -65
105 -91 175 -78 40 8 92 55 73 68 -18 11 -25 10 -40 -6 -8 -7 -34 -16 -60 -20
-43 -6 -48 -5 -82 27 -32 31 -36 40 -36 85 0 28 5 62 11 75 24 53 119 76 154
37 23 -25 55 -26 55 -2 0 24 -19 42 -46 42 -13 0 -26 5 -29 10 -8 13 -40 11
-85 -4z"/>
<path d="M7900 2935 c-14 -8 -31 -17 -39 -21 -45 -23 -56 -47 -56 -128 l0 -78
43 -38 c108 -97 259 -42 272 98 4 34 1 63 -7 76 -7 11 -15 28 -19 36 -27 58
-135 89 -194 55z m105 -39 c91 -38 90 -177 -1 -221 -50 -24 -95 -14 -128 30
-46 60 -28 156 36 191 30 17 51 17 93 0z"/>
<path d="M8280 2935 c-20 -24 -20 -293 0 -305 24 -15 30 -1 30 69 0 84 9 133
24 128 14 -4 55 -87 71 -144 4 -13 11 -23 16 -23 5 0 9 -7 9 -15 0 -28 16 -15
29 23 20 62 54 132 63 132 4 0 8 7 8 15 0 20 16 19 24 -1 3 -9 6 -53 6 -98 0
-46 3 -86 6 -89 4 -3 15 -2 25 3 16 9 18 25 18 156 -1 141 -1 145 -24 156 -46
21 -70 -9 -115 -142 -22 -67 -40 -61 -78 23 -18 39 -32 77 -32 83 0 12 -42 44
-58 44 -5 0 -15 -7 -22 -15z"/>
<path d="M4065 2930 c-3 -5 2 -13 12 -16 10 -3 33 -12 51 -20 l32 -14 0 -124
c0 -124 7 -149 34 -127 9 8 12 45 12 132 -1 133 1 138 61 148 20 3 35 11 34
16 -1 14 -227 19 -236 5z"/>
<path d="M4806 2931 c-12 -19 -8 -282 5 -297 14 -17 164 -20 175 -3 11 18 -9
28 -65 33 -29 2 -57 8 -62 13 -4 4 -10 64 -11 133 -3 107 -5 125 -20 128 -9 2
-19 -1 -22 -7z"/>
<path d="M5137 2933 c-3 -5 -5 -73 -5 -151 1 -109 4 -145 15 -154 28 -23 34 7
31 158 -3 129 -5 149 -20 152 -9 2 -19 -1 -21 -5z"/>
<path d="M7197 2664 c-15 -15 -6 -24 24 -24 20 0 29 4 27 13 -5 14 -40 21 -51
11z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
